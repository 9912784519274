import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/cloud-monitoring/",
    label: "CLOUD MONITORING",
  },
]

const resourcesList = [
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    subtitle: "",
    title:
      "Mid-size technology company prevents unauthorized access to Microsoft 365 account with Vigilant.",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf",
    cat: "SUCCESS STORY",
  },
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="CLOUD MONITORING" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-cm">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>CLOUD MONITORING</h1>
              <h4>RISK REDUCTION AND INTEGRATION</h4>
              <p className="content">
                This is simply the fastest growing way that attackers gain
                access to organizations, requiring constant monitoring of cloud
                workload and cloud-based communications.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    reduce risk today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-overview">
      <div className="container">
        <br />
        <div className="row pb-5">
          <div className="col-sm-12">
            <div className="section-col-rr">
              <h4>Providing the Most Actionable Insights</h4>
              <hr />
            </div>

            <div className="row">
              <div className="col-6">
                <p>
                  In 2021, LinkedIn revealed that 700 million profiles had been
                  exposed in a data scraping breach. Cloud security breaches
                  have surpassed on-site breaches for the first time.
                </p>
                <p>
                  Cloud-based computing offers efficiencies and possibilities
                  for companies and networks, both private and public.
                  Additionally, the post-COVID world has only accelerated this
                  migration. Whether cloud based, cloud-hybrid, multi cloud or
                  cloud on-boarding, this ever-evolving reliance on the cloud
                  brings security risks along with innovations.
                </p>
                <p>
                  The past few years have rapidly accelerated demand for
                  cloud-based architectures. It is impossible for most
                  organizations to maintain the pace of innovation in
                  cloud-centric security while constantly and confidently
                  monitoring workflow across all its data centers, cloud
                  applications and remote work stations.
                </p>
              </div>
              <div className="col-6">
                <p>
                  As of 2021, the cloud is where most malicious activity is
                  being attempted. The cloud is a massive off-site storage bank
                  of seemingly everybody’s most precious data. Vigilant’s
                  ongoing process of assessment provides the most actionable
                  insights into any departures from or misconfigurations of
                  recommended cloud architecture. Vigilant365™ is a “built from
                  the ground up” technology, coupled with our state-of the-art
                  MEDR cloud monitoring, which allows us to ingest the largest
                  data lake into the Vigilant analysis environment. It is a
                  real-time, across-the-board cloud supervisory service, made
                  collaborative through our CyberDNA® Command and Control
                  Center.
                </p>
                <p>
                  From day one, as a part of Vigilant’s MDR service, you will
                  acquire on-going diagnosis of your cloud vulnerabilities from
                  commonly-hacked configurations, unstable permissions and/or
                  logging structures that allow threat activities to hide.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h2>
                Improve Your Cloud Monitoring with <br /> Vigilant365™ Today
              </h2>
              <hr />
              <a
                href="https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf"
                className="nav-section--cta-btn"
                target="__blank"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    learn more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-platform ">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="side-binder-r">
              <h6>Case Study</h6>
              <h3>ANY ACCOUNT IS A THREAT</h3>
            </div>

            <p className="content">
              A client partnered with Vigilant to monitor and secure all of
              their 287 login accounts using our Vigilant365™ service. It turned
              out there were 750 accounts, not 287. Where did these almost 500
              unaccounted for logins come from?
            </p>
            <div className="ctizzle">
              <Link
                to="/account-takeover/case-study"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    read more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <StaticImage
              src="../images/security-maturity/hexAAT.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
